import React from "react"
import FotoCobra from "@components/pageCasosdeExito/image/imgPhotoEmpresa/cobra/FotoCobra.png"
import FotoCobra2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/cobra/FotoCobra2.png"

//logo de empresas clientes
import Cafae from "@components/pageCasosdeExito/image/logoscustomer/Cobra.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import multicanal from "@components/pageCasosdeExito/image/iconcards/multicanal.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/cobra/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/cobra/img2.svg"

const text1 = (
  <span className="">
    Grupo Cobra, es una multinacional española con presencia en 50 países y más
    de 20,000 colaboradores, que cuenta con una amplia gama de servicios, como
    redes, instalaciones personalizadas y proyectos integrados.
    <br /> <br />
    Su filial en Perú, gracias a la API de WhatsApp Business, ha logrado mejorar
    en +20% la contactabilidad con sus clientes finales para confirmar y/o
    reagendar instalaciones de telecomunicaciones. Además de automatizar la
    comunicación con sus técnicos con chatbots.
  </span>
)

const text2 = (
  <span>
    Creado en 1944, Grupo Cobra es un referente mundial con capacidad y
    determinación para desarrollar, crear y operar infraestructuras industriales
    que requieran un alto nivel de servicio; y servicios varios de redes,
    instalaciones y proyectos. Todo, sobre la base de la excelencia en
    integración, innovación tecnológica y solidez financiera.
    <br /> <br />
    Empleando a más de 20,000 colaboradores, su filial en Perú es el partner
    principal de Telefónica, uno de los más grandes proveedores de servicio
    telefónico, internet y cable, a quienes brindan el servicio de instalaciones
    de servicios.
    <br /> <br />
    Luis Pasco Ramirez, Jefe de Operaciones en Grupo Cobra, ha sido la persona
    encargada de poner en marcha el proyecto de implementación del canal de
    WhatsApp en Grupo Cobra. A partir de la problemática que las llamadas no
    estaban siendo contestadas en su mayoría, buscó la manera de mejorar este
    proceso de contacto, encontrando en el API de WhatsApp Business la
    herramienta ideal.
    <br /> <br />A través de Beex, Grupo Cobra accedió rápidamente a la API de
    WhatsApp Business, pudiendo realizar sus envíos masivos de plantillas de
    WhatsApp a sus cientos de usuarios y así lograr el contacto con ellos. Con
    esto, mejoró la experiencia de sus clientes y optimizó el trabajo en campo
    de sus técnicos.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Con los envíos masivos de WhatsApp hemos logrado mejorar nuestra
    contactabilidad en +20%. Nos sirve para todos los casos, sobre todo para
    focalizar zonas donde necesitamos confirmaciones sí o sí, debido a la
    necesidad de órdenes que manejamos.”
  </p>
)

const text5 = (
  <span>
    Gracias al acompañamiento del equipo de Customer Success de Beex, Grupo
    Cobra logró acceder forma rápida a la API de WhatsApp Business. Con esto,
    logró tener un número oficial por este canal de vital importancia para su
    operación. <br />
    Una vez que se dio de alta el canal, se aplicaron 3 acciones básicas que
    necesitaba Grupo Cobra: Gestión multiagente, chatbots y envíos masivos.{" "}
    <br />
    Gracias a la API, pudieron hacer uso inmediato de estas y otras
    funcionalidades que mejoraron los procesos de comunicación de la
    organización, con sus clientes y con sus técnicos. Como menciona Luis, estas
    opciones ahora son indispensables en su operación de mesa y campo.
  </span>
)
const text6 = (
  <span>
    “Actualmente manejamos 2 procesos, mesa y campo; en el primero, Beex nos
    ha ayudado a mejorar los tiempos de devolución de nuestras órdenes, y en el
    segundo, hemos sido capaces de automatizar las respuestas a las preguntas
    que tienen nuestros técnicos, para confirmar las visitas a campo y reducir
    atenciones inefectivas.”
  </span>
)

const text7 = (
  <span>
    Sin duda, una de las funcionalidades que más ha utilizado y que ha ayudado a
    mejorar sus índices operativos a Grupo Cobra, han sido los envíos masivos de
    plantillas de WhatsApp. <br />
    ¿Por qué? Pues, la problemática inicial por la que Grupo Cobra accedió a la
    API de WhatsApp Business fue la baja contactabilidad que estaban
    consiguiendo con sus clientes a través de llamadas telefónicas. Por lo que
    era necesario un canal de mejor tasa de contacto. Este, sin duda, fue
    WhatsApp. <br />
    Entonces, al contar con cientos de clientes, el proceso de contacto se debía
    mejorar por sobre cualquier otro. Por ello, Grupo Cobra empezó a enviar
    plantillas masivas pre-aprobadas de WhatsApp de forma instantánea, ni bien
    tuvo el alta del API, y como lo cuenta Luis, esto se ha visto reflejado en
    sus números.
  </span>
)

const text8 = (
  <span>
    Para Grupo Cobra, el uso de WhatsApp fue mucho más allá que la
    contactabilidad con sus clientes. Por ello, al empezar con el uso de Beex
    y las funcionalidades que le brinda como plataforma, rápidamente decidieron
    sumar el canal para el contacto con sus técnicos de campo.
    <br />
    <br />
    Un punto importante en la implementación ha sido la genial experiencia de
    usuario que encontró Grupo Cobra para crear sus chatbots. Aparte de la
    documentación con la que siempre cuenta nuestra plataforma, la intuitividad de la
    plataforma para crear los chatbots ayudó a que se implementen sin problemas.
    <br />
    <br />
    Por ello, como nos cuenta Luis, los chatbots que sumaron por WhatsApp ha
    mejorado notablemente la experiencia de la operación en general.
  </span>
)

const text9 = (
  <span>
    “Con los chatbots por WhatsApp hemos logrado disminuir nuestros tiempos de
    respuesta en 45%. Nuestros técnicos se pueden comunicar por este canal y
    hemos automatizado muchas preguntas que antes siempre se repetían. Incluso,
    ahora respondemos únicamente a partir de las devoluciones hechas.”
  </span>
)

const text10 = (
  <p>
    Como siempre, el acompañamiento por parte del equipo de soporte de Beex
    ha sido fenomenal.
    <br />
    <br />
    Desde el acceso al API de WhatsApp Business, hasta la capacitación y ayuda
    continua. Nuestro equipo de Customer Success ha acompañado a Cobra en el uso
    de nuestra solución omnicanal.
    <br />
    <br />
    Como menciona Luis, aparte de que nuestra solución es confiable en uso, el
    sumar el soporte dedicado que brindamos, hace que la experiencia en general
    sea memorable.
  </p>
)

const text11 = (
  <span>
    “Como empresa nos hemos sentido respaldados y muy a gusto con el servicio
    brindado. Beex brinda un servicio confiable y su soporte es rápido,
    teniendo respuestas a los pocos minutos de contacto.”
  </span>
)
const text12 = (
  <p>
    Estos son algunos procesos con los que Beex ayuda
    a Grupo Cobra en su operación de atención al cliente y soporte interno:
  </p>
)

const text13 = (
  <p>
    Estamos felices de contar con un cliente tan representativo
    como Grupo Cobra. <br />
    ¿Quién no ha visto por la calle sus habituales movilidades de instalación?
    <br />
    Pues, nos enorgullece ayudarlos en mejorar el contacto y comunicación de sus
    cientos de usuarios con la marca.
  </p>
)

const data = {
  intro: {
    image: Cafae,
    link: "#",
    classlogo: "logo__clientes--casa",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoCobra,
    text4: "",
    title5: "WhatsApp Business API",
    text5: text5,
    text6: text6,
    title7: "Envíos masivos de WhatsApp",
    text7: text7,
    title8: "Chatbots por WhatsApp",
    text8: text8,

    fototwo: FotoCobra2,
    text9: text9,
    title10: "Experiencia de soporte",
    text10: text10,
    text11: text11,
    text12: text12,
    text13: text13,

    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para su operación de atención al cliente y soporte interno. Beex ha logrado mejorar la contactabilidad con sus clientes mediante la app más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más.",
    },
   
    {
      title: "Campañas masivas",
      img: multicanal,
      info:
        "Envíos de campañas masivas de WhatsApp para su operación de soporte al cliente. Proceso simple y rápido con el uso de plantillas pre-aprobadas de WhatsApp. Mejora en su KPI más importante: % de contactabilidad.",
    },
    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots para su operación de soporte interno por WhatsApp. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
  ],
}

export default data
